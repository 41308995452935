// Custom Css variables
\:root
  --cat-transport: #{$cat-transport}
  --cat-realEstate: #{$cat-realEstate}
  --cat-clothes: #{$cat-clothes}
  --cat-services: #{$cat-services}
  --cat-health: #{$cat-health}
  --cat-animals: #{$cat-animals}
  --cat-garden: #{$cat-garden}
  --cat-electronic: #{$cat-electronic}
  --cat-construction: #{$cat-construction}
  --cat-homeStuff: #{$cat-homeStuff}
  --cat-food: #{$cat-food}
  --cat-agriculture: #{$cat-agriculture}
  --cat-production: #{$cat-production}
  --cat-kids: #{$cat-kids}
  --cat-hobby: #{$cat-hobby}
  --cat-charity: #{$cat-charity}
  --cat-adultContent: #{$cat-adultContent}
  --cat-discount: #{$cat-discount}
  --cat-hover-bg: #{$category-background-gray}
  --cat-menu-bg: #{$pp-white}
  --feedback-hate: #{$feedback-hate}
  --feedback-bad: #{$feedback-bad}
  --feedback-neutral: #{$feedback-neutral}
  --feedback-good: #{$feedback-good}
  --feedback-loved: #{$feedback-loved}
  --header-bg: #{$header-bg}
  --header-search-bg: #{$header-search-bg}
  --header-search-hover-bg: #{$gray-ECECEC}
  --header-search-color: #{$pp-black}
  --cat-menu-text: #{$pp-black}
  --lot-bg-color: #{$lot-bg-color}
  --masonry-price: #{$masonry-header-color}
  --masonry-text: #{$text-gray}
  --masonry-header-color: #{$masonry-header-color}
  --masonry-footer-bg: #{$gray-F6F6F6}
  --masonry-menu-bg: #{$pp-white}
  --masonry-hover-menu: #{$gray-F3F3F3}
  --masonry-border-color: #{$pp-white}
  --cat-hover: #{$pp-black}
  --footer-bg: #{$pp-footer}
  --breadcrumb-bg: #{$breadcrumb-bg}
  --breadcrumb-border: #{$breadcrumb-border}
  --breadcrumb-text: #{$breadcrumb-text}
  --bg-color: #{$bg-color}
  --pp-bg-mobile: #{$pp-bg}
  --body-bg-mobile: #{$brand-color}
  --border-color: #{$border-color}
  --default-text-color: #{$pp-black}
  --inverted-link-color: #{$link-black}
  --inverted-text-color: #{$pp-black}
  --btn-primary-icon-color: #{$pp-white}
  --btn-secondary-bg: #{$btn-action}
  --btn-secondary-border: #{$pp-gray-border}
  --btn-secondary-hover-bg: #{$btn-action-hover}
  --btn-secondary-hover-border: #{$btn-action-hover-border}
  --btn-secondary-hover-text: #{$gray-800}
  --btn-is-on-bg: #{$btn-is-on-bg}
  --btn-is-on-border: #{$btn-is-on-border}
  --btn-burger-color: #{$pp-white}
  --btn-burger-hover-color: #{$pp-white}
  --pp-menu: #{$pp-menu-blue}
  --pp-bg-gray: #{$pp-bg-grey}
  --category-name-color: #{$pp-black}
  --form-select-bg: #{$form-select-bg}
  --form-select-color: #{$form-select-color}
  --form-select-border: #{$form-select-border-color}
  --form-input-bg: #{$form-input-bg}
  --form-input-color: #{$pp-black}
  --form-input-border: #{$form-input-border}
  --form-label-color: #{$form-label-color}
  --form-label-bg: #{$pp-white}
  --form-check-label-bg: #{$gray-EDEDED}
  --form-check-label-border: #{$pp-gray-border}
  --form-legend-color: #{$form-legend-color}
  --tab-selected-bg: #{$pp-white}
  --tab-border-color: #{$tab-border-color}
  --tab-bg-color: #{$tab-background-color}
  --collapse-bg: #{$collapse-bg}
  --trans-cat: #{$bg-white}
  --create-step-bg: #{$create-step-bg}
  --create-step-inactive: #{$create-step-inactive}
  --create-step-border: #{$create-step-border}
  --filter-value-bg: #{$filter-value-bg}
  --placeholder-color: #{$pp-placeholder-default}
  --image-placeholder-color: #{$pp-white}
  --image-placeholder-bg: #{$image-placeholder-bg}
  --style-page: #{$pp-style-page}
  --menu-border-color: #{$pp-blue-border}
  --loader-svg-color: #{$loader-svg-color}
  --loader-bg-color: #{$loader-bg-color}
  --error-code: #{$error-code}
  --static-gradient: #{$static-gradient}
  --static-border: #{$static-border}
  --box-shadow: #{$box-shadow-sm}
  --bs-tooltip-zindex: #{$zindex-tooltip}
  --icon-color-hover: #{$icon-color-hover}
  --pp-block-bg: var(--bg-color)
  --pp-block-border: var(--border-color)
  --menu-icon-color: #{$pp-white}
  --menu-icon-color-hover: #{$pp-white}
  --toggle-off: #{$toggle-off}
  --toggle-on: #{$toggle-on}
  --skeleton-bg: #{$skeleton-bg}
  --cat-transport-menu: #{$brand-color}
  --fixed-controls-bg: #{$border-color}
  .btn-secondary,
  .btn-action,
  .form-group
    --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator)}
    --bs-btn-border-color: #{$pp-btn-secondary-border}
    --bs-btn-hover-border-color: #{$btn-action-hover-border}
    --bs-btn-color: #{$pp-black}
  .modal,
  .modal-gdpr
    --bs-modal-bg: #{$bs-modal-bg}
    --bs-modal-color: #{$bs-modal-color}
    .prompt
      --bs-modal-bg: #{$pp-white}
      --bs-modal-color: #{$pp-black}

@if $enable-dark-mode
  @include color-mode(dark, true)
    --bs-link-color-rgb: #{$link-color}
    --header-bg: #{$header-bg-dark}
    --header-search-hover-bg: #{$header-search-hover-bg-dark}
    --header-search-bg: #{$header-search-bg-dark}
    --header-search-color: #{$header-search-color-dark}
    --cat-menu-text: #{$pp-white}
    --lot-bg-color: #{$lot-bg-color-dark}
    --masonry-price: #{$masonry-price-dark}
    --masonry-text: #{$masonry-text-dark}
    --masonry-header-color: #{$masonry-header-color-dark}
    --masonry-footer-bg: #{$masonry-footer-bg-dark}
    --masonry-menu-bg: #{$masonry-menu-bg-dark}
    --masonry-hover-menu: #{$masonry-hover-menu-dark}
    --masonry-border-color: #{$masonry-border-color-dark}
    --cat-hover: #{$pp-white}
    --cat-hover-bg: #{$cat-hover-bg-dark}
    --cat-menu-bg: #{$cat-menu-bg-dark}
    --footer-bg: #{$pp-footer-dark}
    --footer-text-color: #{$footer-text-color-dark}
    --breadcrumb-bg: #{$breadcrumb-bg-dark}
    --breadcrumb-border: #{$breadcrumb-border-dark}
    --breadcrumb-text: #{$breadcrumb-text-dark}
    --bg-color: #{$bg-color-dark}
    --pp-bg-mobile: #{$pp-bg-dark}
    --body-bg-mobile: #{$header-bg-dark}
    --border-color: #{$border-color-dark}
    --default-text-color: #{$masonry-text-dark}
    --inverted-link-color: #{$link-light}
    --inverted-text-color: #{$link-light}
    --btn-secondary-bg: #{$btn-action-dark}
    --btn-secondary-border: #{$masonry-border-color-dark}
    --btn-secondary-hover-bg: #{$btn-action-hover-dark}
    --btn-secondary-hover-border: #{$btn-action-hover-border-dark}
    --btn-secondary-hover-text: #{$gray-500}
    --btn-is-on-bg: #{$btn-is-on-bg-dark}
    --btn-is-on-border: #{$btn-is-on-border-dark}
    --btn-burger-color: #{$btn-burger-color-dark}
    --btn-burger-hover-color: #{$btn-burger-hover-color-dark}
    --pp-menu: #{$pp-menu-dark}
    --base-color: #{$pp-black}
    --pp-bg-gray: #{$pp-bg-grey-dark}
    --category-name-color: #{$pp-white}
    --form-select-bg: #{$form-select-bg-dark}
    --form-select-color: #{$form-select-color-dark}
    --form-input-bg: #{$form-input-bg-dark}
    --form-select-border: #{$form-input-bg-dark}
    --form-input-color: #{$form-input-color-dark}
    --form-input-border: #{$form-input-bg-dark}
    --form-label-color: #{$form-label-color-dark}
    --form-label-bg: #{$form-check-label-bg-dark}
    --form-check-label-bg: #{$form-check-label-bg-dark}
    --form-check-label-border: #{$form-check-label-border-dark}
    --form-legend-color: #{$form-label-color-dark}
    --tab-selected-bg: var(--bs-body-bg)
    --tab-border-color: #{$tab-border-color-dark}
    --tab-bg-color: #{$tab-background-color-dark}
    --collapse-bg: #{$collapse-bg-dark}
    --trans-cat: #{$pp-menu-dark}
    --create-step-bg: #{$create-step-bg-dark}
    --create-step-inactive: #{$create-step-inactive-dark}
    --create-step-border: #{$create-step-border}
    --filter-value-bg: #{$filter-value-bg-dark}
    --placeholder-color: #{$pp-placeholder-default-dark}
    --image-placeholder-color: #{$image-placeholder-color-dark}
    --image-placeholder-bg: #{$image-placeholder-dark}
    --style-page: #{$pp-style-page-dark}
    --menu-border-color: #{$border-color-dark}
    --loader-svg-color: #{$loader-svg-color-dark}
    --loader-bg-color: #{$loader-bg-color-dark}
    --filter-invert: invert(1)
    --error-code: #{$error-code-dark}
    --static-gradient: #{$static-gradient-dark}
    --static-border: #{$static-border-dark}
    --icon-color-hover: #{$icon-color-hover-dark}
    --pp-block-bg: var(--lot-bg-color)
    --pp-block-border: #{$masonry-border-color-dark}
    --menu-icon-color: var(--masonry-text)
    --menu-icon-color-hover: var(--btn-secondary-hover-text)
    --toggle-off: #{$toggle-off-dark}
    --toggle-on: #{$toggle-on-dark}
    --skeleton-bg: #{$skeleton-bg-dark}
    --cat-transport-menu: #{$cat-transport}
    --fixed-controls-bg: #{$header-bg-dark}
    .btn-secondary,
    .btn-action,
    .form-group
      --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator)}
      --bs-btn-bg: #{$btn-secondary-bg-dark}
      --bs-btn-color: #{$btn-secondary-color-dark}
      --bs-btn-border-color: #{$btn-secondary-border-dark}
      --bs-btn-hover-bg: #{$btn-secondary-hover-bg-dark}
      --bs-btn-hover-color: #{$btn-secondary-hover-color-dark}
      --bs-btn-active-bg: #{$btn-active-bg-dark}
      --bs-btn-active-border-color: #{$btn-active-border-color-dark}
      --bs-btn-active-color: #{$btn-active-color-dark}
      --bs-btn-hover-border-color: #{$btn-action-hover-border-dark}
    .modal
      --bs-modal-bg: #{$modal-bg-dark}
      --bs-modal-color: #{$pp-white}
      .prompt
        --bs-modal-bg: #{$modal-bg-dark}
        --bs-modal-color: #{$pp-white}
    .modal-gdpr
      --bs-modal-bg: #{$bs-modal-bg}
      --bs-modal-color: #{$bs-modal-color}
    .modal-content
      --bs-modal-border-color: #{$modal-border-dark}
      --bs-modal-border-width: 1px
    .modal-backdrop
      --bs-backdrop-bg: #{$modal-backdrop-dark}
    .react-loading-skeleton
      --base-color: #{$skeleton-bg-dark}
      --highlight-color: #{$skeleton-highlight-dark}
    .dropdown-menu
      --bs-dropdown-bg: #{$masonry-menu-bg-dark}
    .page-link
      --bs-pagination-bg: #{$btn-secondary-bg-dark}
      --bs-pagination-border-color: #{$btn-secondary-border-dark}
      --bs-pagination-hover-bg: #{$btn-secondary-hover-bg-dark}
      --bs-pagination-active-bg: #{$btn-active-bg-dark}
    .fieldset-label
      label
        margin: 0
        border-radius: .5rem
        padding: 0 .5rem
    .pp-single-pp-block
      .collapse-legend
        .legend,
        .btn-collapse
          background-color: var(--pp-block-bg)
    .fixed
      &__controls
        &.fixed
          box-shadow: rgba($gray-A7A6A6, 0.35) 0 6px 100px 0
